import React, { useRef } from "react"
import { useTranslation } from "react-i18next"

const useConstructor = (callBack = () => {}) => {
  const hasBeenCalled = useRef(false)
  if (hasBeenCalled.current) return
  callBack()
  hasBeenCalled.current = true
}

const PageContext = React.createContext({})

export const PageContextProvider = ({ pageContext, children }) => {
  const { i18n } = useTranslation()

  /* Renders a page with the translated language (during build) */
  useConstructor(() => {
    i18n.changeLanguage(pageContext.locale)
  })

  return (
    <PageContext.Provider pageContext={pageContext}>
      {children}
    </PageContext.Provider>
  )
}

export const usePageContext = () => React.useContext(PageContext)
