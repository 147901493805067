import "normalize.css"
import "./src/styles/global.css"
import "./src/styles/typography.css"

import "./src/i18n/config"

export { wrapPageElement, wrapRootElement } from "./gatsby-ssr"

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
